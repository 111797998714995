<template>
    <div class="smartjobbox">
        <div class="smarttitle">
                智能岗位推荐
            </div>
        <div class="smartlist flex align-center" >
            
            <div class="smartitem" v-for="(item,index) in list" @click="routeToJobDetail(item)">
                <div class="itemtop flex align-center justify-between">
                    <strong>{{item.workName}}</strong>
                    <span>{{item.salaryName}}</span>
                </div>
                <div class="itembot">
                     {{item.companyName}} 
                </div>
            </div>
        </div>
        <div class="page">
            <el-pagination
            background
            layout="prev, pager, next"
            :page-size="size"
            @current-change="changepage"
            @prev-click="prevpage"
            @next-click="nextpage"
            :current-page="page"
            :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import userRequest from "@/api/user";
export default{
    data(){
        return{
            list:[],
            total:0,
            page:1,
            size:20,
            loading:false,
        }
    },
    created(){
        this.getList()
    },
    methods:{
        routeToJobDetail(item) {

            const {href} = this.$router.resolve({
                path: '/user/jobDetail',
                query: {
                workId: item.id,
                workName: item.workName,
                }
            })
            window.open(href, '_blank')



        },
        getList(){
            userRequest.homeQueryWork({pageNum:this.page,pageSize:this.size,isRecommend:4,isApp:true}).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.list=res.data.list
                }
            })
        },
         changepage(e){
            this.page=e
            this.getList()
        },
        prevpage(){
            this.page=this.page-1
            if(this.page<=0){
                this.page=1
            }
            this.getList()
        },
        nextpage(){
            this.page=this.page+1
            let maxnum=Mail.ceil(this.total/this.size)
            if(this.page>=maxnum){
                this.page=maxnum
            }
            this.getList()
        }
    }
}
</script>

<style scoped>
.smartjobbox{ width: 1200px; margin: 16px auto 18px auto; background: #ffffff; box-sizing: border-box; padding: 16px;}
.smartlist{ flex-wrap: wrap;}
.smartitem{ width: 283px; cursor: pointer; margin-right: 11px; margin-bottom: 10px; padding:15px 12px; height: 76px; background: #fbfcfd; border: #f0f3f6 solid 1px;}
.smartitem:nth-child(4n){ margin-right: 0;}
.itemtop span{ font-size: 14px; color: #f65959;}
.itemtop strong{ font-size: 15px;}
.itembot{ font-size: 13px; color: #b9baba; margin-top: 13px;}
.smarttitle{ text-align: center; font-size: 18px; font-weight: bold; padding-top: 10px; padding-bottom: 25px;}
.page{ text-align: center; padding:30px 0;}
.page /deep/ .el-pager li.active{color:#ffffff!important;}
</style>
